import React from 'react'
import Helmet from 'react-helmet'
import SEO from '../components/seo'
import Layout from '../components/Layout'
import ArticleViewer from '../components/ArticleViewer'
import { graphql } from 'gatsby'

// import '../styles/templates/Issue.css'

export default function Template({ data }) {
  let {
    allPrismicArticle: {
      edges: [
        {
          node: { data: article },
        },
      ],
    },
  } = data
  return (
    <Layout>
      <SEO
        title={`CONTANGO - ${article.article_title.text}`}
        keywords={['Contango Journal', 'Political Theory']}
      />
      <Helmet title={`CONTANGO - ${article.article_title.text}`} />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <ArticleViewer article={article} />
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query PrismicArticle($uid: String!) {
    allPrismicArticle(filter: { uid: { eq: $uid } }) {
      edges {
        node {
          id
          data {
            article_pdf {
              url
            }
            article_title {
              text
            }
            article_description {
              html
            }
          }
        }
      }
    }
  }
`
