import React, { Component } from 'react'
import { Document, Page } from 'react-pdf'
import ReactLoading from 'react-loading'

import './ArticleViewer.css'
import zoomOutIcon from '../../images/icons/mui-zoom-out.svg'
import zoomInIcon from '../../images/icons/mui-zoom-in.svg'

export default class ArticleViewer extends Component {
  state = {
    numPages: undefined,
    scale: 2.5,
  }

  setPageCount = ({ numPages }) => {
    this.setState({ numPages })
  }

  zoom = scale => {
    if (this.state.scale > 0.5 || scale > 0) {
      this.setState({ scale: scale + this.state.scale })
    }
  }

  showZoomCtrls = show => {
    this.setState({ showViewer: show })
  }

  handleWindowResize = () => {
    if (window.innerWidth <= 1300 && window.innerWidth > 1000) {
      this.setState({ scale: 2.0 })
    } else if (window.innerWidth <= 1000 && window.innerWidth > 800) {
      this.setState({ scale: 1.5 })
    } else if (window.innerWidth < 800) {
      this.setState({ scale: 1.0 })
    } else {
      this.setState({ scale: 2.5 })
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleWindowResize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize)
  }

  render() {
    return (
      <Document
        file={this.props.article.article_pdf.url}
        onLoadSuccess={this.setPageCount}
        className="document"
        loading={
          <ReactLoading
            type={'spokes'}
            color={'black'}
            height={'100px'}
            width={'100px'}
          />
        }
      >
        <div className="pageContainer">
          {Array.from(new Array(this.state.numPages), (el, index) => (
            <Page
              key={`page_${index + 1}`}
              pageNumber={index + 1}
              scale={this.state.scale}
              className="page"
            />
          ))}
          <div
            className={
              this.state.showViewer
                ? 'zoomBtnContainer'
                : 'zoomBtnContainer fadeOut'
            }
            onMouseOver={() => this.showZoomCtrls(true)}
            onMouseLeave={() => this.showZoomCtrls(false)}
          >
            <img
              alt="Zoom Out"
              className="zoomIcon"
              src={zoomOutIcon}
              onClick={() => this.zoom(-0.5)}
            />
            <img
              alt="Zoom In"
              className="zoomIcon"
              src={zoomInIcon}
              onClick={() => this.zoom(0.5)}
            />
          </div>
        </div>
      </Document>
    )
  }
}
